<script lang="ts">
	import Calendar from "./calendar.svelte";
	// import News from './news.svelte';
	import { GetActiveLocationsCalendars } from "src/graphql-operations";
	import userStore from "src/stores/user";

	const activeLocations = GetActiveLocationsCalendars({
		variables: { team_id: userStore.getSelectedProjectId() },
	});
	let calendarId;
</script>

<div
	class="flex w-full flex-col space-y-8 md:flex-row md:space-x-16 md:space-y-0 xl:space-x-32"
>
	<div class="flex flex-col md:w-1/2">
		<div class="w-full">
			<h1 class="mb-2 text-2xl font-semibold text-neutral-700">
				Agenda da semana
			</h1>
			<span class="text-neutral-700">
				Confira os eventos mais importantes que acontecem essa semana escolhendo
				a região de sua preferência.
			</span>

			{#if !$activeLocations.loading}
				<select
					class="mt-5 w-full rounded-md border-none bg-neutral-200/50 px-4 py-2 text-neutral-500"
					bind:value={calendarId}
				>
					{#each $activeLocations.data.all as location}
						<option
							selected={location.calendar_id ==
								$activeLocations.data.default[0]?.calendar_id}
							value={location.calendar_id}
						>
							{location.names[0]?.name}
						</option>
					{/each}
				</select>
			{/if}

			<div class="mt-6 flex w-full flex-row space-x-8">
				<a
					class="calendar-button"
					href="https://calendar.google.com/calendar/u/0/embed?src={calendarId}"
					target="_blank"
				>
					Agenda do Polo
				</a>
				<a
					class="calendar-button"
					href="https://calendar.google.com/calendar/u/0/embed?src={import.meta
						.env.VITE_GOOGLE_CALENDAR_GENERAL_ID}"
					target="_blank"
				>
					Agenda Geral
				</a>
			</div>
		</div>

		<!-- <div class="w-full">
			<News />
		</div> -->
	</div>

	<div class="md:w-1/2">
		<Calendar {calendarId} />
	</div>
</div>

<style>
	.calendar-button {
		background: #ffffff;
		border-radius: 6px;
		padding: 0.5rem 1rem;
		color: #7e8a9a;
	}
</style>
